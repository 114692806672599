import { derived, get, writable } from 'svelte/store'
import {
  api as Api,
  apiCallDescription,
  apiCallIceCandidates,
  apiCallMessages,
  apiCallRemoteStream,
  apiCallStarted,
} from './api'

import { localStream } from './stream'
import { analyticsTrack } from './analytics'
import type { ApiTypes } from '@twixer/core'
import { secsToNow } from './utils'

const api = () => get(Api)

export type AppState = 'home' | 'match' | 'account' | 'referrals'

export const ages = [
  { name: '7+', value: 'JUNIOR' as const },
  { name: '13+', value: 'TEEN' as const },
  { name: '18+', value: 'ADULT' as const },
]

export const appState = writable<AppState>('home')
export const appCurrentMatch = writable<ApiTypes.MatchData | null>(null)
export const appMatchmakingAt = writable(new Date())
export const appCallAt = writable(new Date())
export const appReceivedGift = writable<ApiTypes.GiftReceived | null>(null)
export const appReceivedRpsInvite = writable<{ senderId: string } | null>(null)
export const appCurrentRpsMatch = writable<{ opponentId: string } | null>(null)
export const appRpsMatchEnded = writable<{
  winnerId?: string
  opponentPick: 'ROCK' | 'PAPER' | 'SCISSORS',
  opponentId: string,
  yourPick: 'ROCK' | 'PAPER' | 'SCISSORS'
} | null>(null)
export const appReceivedFriendRequest = writable<ApiTypes.ReceivedFriendRequest | null>(null)

export const appService = {
  startMatch: () => {
    appState.set('match')
    appMatchmakingAt.set(new Date())

    localStream
      .start()
      .then(() => {
        api().client.emit('matchmaking:start')
      })
      .catch(() => {
        // TODO: show error
        appState.set('home')
      })
  },

  setMatch: (match: ApiTypes.MatchData) => {
    appCurrentMatch.set(match)
    appCurrentRpsMatch.set(null)
    appReceivedFriendRequest.set(null)
    appReceivedRpsInvite.set(null)
    appCallAt.set(new Date())
    apiCallStarted.set(null)
    apiCallDescription.set(null)
    apiCallIceCandidates.set([])
    apiCallMessages.set([])
    apiCallRemoteStream.set(null)
    analyticsTrack('VideoChat Started', { TimeToWait: secsToNow(get(appMatchmakingAt)) })
  },

  onCallStarted: (ev: ApiTypes.CallStarted) => {
    console.log('Call has started')

    apiCallStarted.set(ev)
    apiCallDescription.set(null)
    apiCallIceCandidates.set([])
    apiCallMessages.set([])
  },

  onCallEnded: () => {
    if (get(appState) === 'match' && get(appCurrentMatch)) {
      appCurrentMatch.set(null)
      appReceivedGift.set(null)
      appMatchmakingAt.set(new Date())
      appCurrentRpsMatch.set(null)
      appReceivedRpsInvite.set(null)
      appReceivedFriendRequest.set(null)

      console.log('Call has ended')

      setTimeout(() => {
        if (get(appState) === 'match') {
          api().client.emit('matchmaking:start')
        }
      }, 3000)
    }
  },

  stop: () => {
    if (get(appState) === 'match') {
      if (get(appCurrentMatch)) {
        appCurrentMatch.set(null)
        api().client.emit('call:end')
      } else {
        api().client.emit('matchmaking:stop')
      }
    }
    appReceivedGift.set(null)
    appState.set('home')

    // Safari dom freeze fix
    setTimeout(() => localStream.stop(), 400)
  },

  nextMatch: () => {
    api().client.emit('call:end')
    appCurrentMatch.set(null)
    appCurrentRpsMatch.set(null)
    appReceivedRpsInvite.set(null)
    appState.set('match')
    appMatchmakingAt.set(new Date())
    api().client.emit('matchmaking:start')
  },

  sendGift: (receiverId: string, giftId: number) => {
    return api().client.timeout(3000).emitWithAck('gift:send', { receiverId, giftId })
  },

  addRating: (toUserId: string) => {
    api()
      .client.timeout(3000)
      .emitWithAck('user:add-rating', { toUserId })
      .then((u) => {
        appCurrentMatch.update((match) => {
          if (match && match.partner.id === toUserId) {
            return {
              matchId: match.matchId,
              partner: u,
            }
          } else {
            return match
          }
        })
      })
      .catch((e) => console.error('addRating', e))
  },
}
