import { PUBLIC_API_URL } from '$env/static/public'
import { io as ioClient, Socket } from 'socket.io-client'
import type { ApiTypes } from '@twixer/core'
import { derived, writable } from 'svelte/store'
import { browser } from '$app/environment'
import {
  appService,
  appReceivedFriendRequest,
  appReceivedGift,
  appReceivedRpsInvite,
  appCurrentRpsMatch,
  appRpsMatchEnded,
} from './app-service'
import type GiftPopup from './components/Call/GiftPopup.svelte'

type Api = Socket<ApiTypes.ServerToClientEvents, ApiTypes.ClientToServerEvents>

export const apiCurrentUser = writable<ApiTypes.CurrentUser | null>(null)
export const currentUserRating = derived(apiCurrentUser, (user) => user?.rating ?? 0)
export const apiCallStarted = writable<ApiTypes.CallStarted | null>(null)
export const apiCallDescription = writable<RTCSessionDescriptionInit | null>(null)
export const apiCallIceCandidates = writable<(RTCIceCandidateInit | null)[]>([])
export const apiCallRemoteStream = writable<MediaStream | null>(null)
export const apiCallMessages = writable<ApiTypes.ChatMessage[]>([])
export const api = apiClient()

const io: Api = ioClient(PUBLIC_API_URL, {
  autoConnect: false,
  transports: ['websocket', 'polling'],
})
  .onAny((...args) => console.log('RCVD:', ...args))
  .onAnyOutgoing((...args) => console.log('SENT:', ...args))
  .on('connect', () => {
    console.log('API connected!', io.recovered)
  })

interface ApiClient {
  state: 'loading' | 'error' | 'connected'
  client: Api
  error?: string
}

function apiClient() {
  const store = writable<ApiClient>({ state: 'loading', client: null as any }, (set) => {
    if (!browser) return

    io.on('connect', () => {
      set({ state: 'connected', client: io })
    })

    io.on('connect_error', (err) => {
      set({ state: 'error', client: null as any, error: String(err) })
    })

    io.on('error', (error) => {
      set({ state: 'error', client: null as any, error })
    })

    io.on('currentUser', (user) => {
      apiCurrentUser.set(user)
    })

    io.on('gift:received', (data) => {
      appReceivedGift.set(data)
    })

    io.on('game:rps:match-started', (data) => {
      appReceivedRpsInvite.set(null)
      appCurrentRpsMatch.set(data)
    })

    io.on('game:rps:match-ended', (data) => {
      appCurrentRpsMatch.set(null)
      appRpsMatchEnded.set(data)
    })

    io.on('game:rps:request-received', (data) => {
      appReceivedRpsInvite.set(data)
    })

    io.on('friendship:request-received', (data) => {
      appReceivedFriendRequest.set(data)
    })

    io.on('match:created', (data) => {
      appService.setMatch(data)
    })

    io.on('call:started', (data) => {
      appService.onCallStarted(data)
    })

    io.on('call:ended', () => appService.onCallEnded())

    io.on('call:description', (desc) => {
      apiCallDescription.set(desc)
    })

    io.on('call:iceCandidate', (candidate) => {
      apiCallIceCandidates.update((candidates) => [...candidates, candidate])
    })

    io.on('call:messages', (messages) => {
      apiCallMessages.set(messages)
    })

    if (!io.connected) {
      if (!('Telegram' in window)) {
        throw new Error('Use Telegram messenger!')
      }

      io.auth = { initData: Telegram.WebApp.initData }
      io.connect()
    }

    return () => {
      io.disconnect()
    }
  })

  return {
    subscribe: store.subscribe,
  }
}
