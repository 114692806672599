import { PUBLIC_AMPLITUDE_API_KEY } from '$env/static/public'
import * as amplitude from '@amplitude/analytics-browser'
import type { ApiTypes } from '@twixer/core'

export function analyticsInitialize(user: ApiTypes.CurrentUser, data: { channel?: string }) {
  amplitude.init(PUBLIC_AMPLITUDE_API_KEY, user.id, { defaultTracking: false })

  const identifyObj = new amplitude.Identify()
  identifyObj.set('name', user.name)
  identifyObj.set('gender', user.gender)
  identifyObj.set('lang', user.lang)
  if (data.channel !== undefined && data.channel.length > 0) {
    identifyObj.set('channel', data.channel)
  }
  amplitude.identify(identifyObj)
}

export function analyticsTrack(event: string, properties?: Record<string, unknown>) {
  amplitude.track(event, properties)
  console.log(`Analytics: ${event}`, properties ?? '')
}

export function analyticsFlush() {
  return amplitude.flush()
}
