import { get, writable } from 'svelte/store'
import { analyticsTrack } from './analytics'
import { captureException, captureMessage } from '@sentry/sveltekit'
import { appService } from './app-service'

interface LocalStream {
  audioTrack: MediaStreamTrack
  videoTrack: MediaStreamTrack
  isVideoUserFacing: boolean
}

const videoConstraints: MediaTrackConstraints = {
  width: 640,
  height: 480,
}

function createLocalStreamStore() {
  const store = writable<LocalStream | null>(null)
  const { set, subscribe, update } = store

  let facingMode: 'user' | 'environment' = 'user'

  return {
    start: async () => {
      const oldStream = get({ subscribe })
      if (oldStream) return

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: { ...videoConstraints, facingMode },
        })

        const audioTrack = stream.getAudioTracks()[0]
        const videoTrack = stream.getVideoTracks()[0]
        set({
          audioTrack,
          videoTrack,
          isVideoUserFacing: facingMode === 'user',
        })

        analyticsTrack('VideoChat CamMic Access Granted')

        stream
          .getTracks()
          .forEach((track) =>
            console.log(`Got track: ${track.kind} ${track.label} ${track.id}`, track.getSettings())
          )
      } catch (e) {
        console.error('localStream error', e)
        analyticsTrack('VideoChat CamMic Access Denied')
        captureException(e)
        throw e
      }
    },
    switchCamera: async () => {
      const oldStream = get({ subscribe })
      if (!oldStream) {
        captureMessage('switchCamera called when no stream!')
        return
      }

      facingMode = facingMode === 'user' ? 'environment' : 'user'

      const stream = await navigator.mediaDevices.getUserMedia({
        video: { ...videoConstraints, facingMode },
      })

      oldStream.videoTrack.stop()

      set({
        audioTrack: oldStream.audioTrack,
        videoTrack: stream.getVideoTracks()[0],
        isVideoUserFacing: facingMode === 'user',
      })
    },
    stop: () => {
      update((oldStream) => {
        if (oldStream) {
          console.log('createLocalStreamStore: stop()')
          oldStream.videoTrack.stop()
          oldStream.audioTrack.stop()
        }
        return null
      })
    },
    subscribe,
  }
}

export const localStream = createLocalStreamStore()
